import React from "react";

function SuperAdminTestPage() {
  return (
    <div>
      <h1>SuperAdminTestPage</h1>{" "}
    </div>
  );
}

export default SuperAdminTestPage;
