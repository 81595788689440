export default function EnquiryDetailSkeleton() {
  return (
    <div class="enq-skeleton-card">
      <div class="enq-skeleton-card-img enq-skeleton">

      </div>
      <div class="enq-skeleton-card-body">
        <h2 class="enq-skeleton-card-title enq-skeleton">

        </h2>
        <p class="enq-skeleton-card-intro enq-skeleton">

        </p>
      </div>
    </div>)
}