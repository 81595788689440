export const MaterialStyleTypes = {
    Curtain: "Curtain",
    customization_Curtain: "customization_Curtain",
    Mock: "Mock",
    Sheer: "Sheer",

    Belt: "Belt",
    BeltBorder: "BeltBorder",
    BeltPiping: "BeltPiping",

    Border: "Border",
    Pelmet: "Pelmet",
    Hardware: "Hardware",
    Lining: "Lining",
    Rods: "Rods",
    Track: "Track",

    //for second border fabric image
    Border2: "Border_two",

    Blind: "Blinds",
    Blind_fabric: "BlindFabric",
    Blind_border_fabric: "BlindBorderFabric",
    Blind_shade: "Blind_shade",

    windowImage: "windowImage",
    Blind_fabric_Image: "Blind_fabric_Image",
    Blind_Border_Image: "Blind_Border_Image",
    Blind_Border_Curtain_fabric_Image: "Blind_Border_Curtain_fabric_Image",

    Upholstery: "Upholstery",// Sofa
    Wallpaper: "Wallpaper",
    flooring: "WoodenFlooring",
    SiteImage: "SiteImage",

    PrimaryTrack: "PrimaryTrack",
    SecondaryTrack: "SecondaryTrack",
    MockTrack: "MockTrack",

    PrimaryRod: "PrimaryRod",
    SecondaryRod: "SecondaryRod"

};

export const MaterialCategoryName = {
    sheer: "sheers",
    curtain: "Curtains",
    blind: "Blinds",
    track: "Rods/tracks",
    Upholstery: "Upholstery",
    Wallpaper: "Wallpaper",
    flooring: "Flooring/Carpet"
};


export const SofaSelectionType = {
    sofa: "Sofa",
    Pouffe: "Pouffee",
    BedBack: "Bedback",
    WallPanel: "Wallpanel",
    cushion: "Cushion"
}